import {get } from "../helpers/api";

export default {
    data() {
        return {
            applicationsType: [],
            marketing_sources: [],
            application_comments: [],
            application_status: {},
            form_application: false,
            form: {
                type_id: 0,
                theme_ids: [],
                comment: '',
                source: "",
                status: 0,
                application_id: 0,
                application_comment: '',
                product_ids: [],
            },
        };
    },
    methods: {
        loadApplicationTypes() {
            get('/app-types')
                .then((response) => {
                    if (response.data.success) {
                        this.applicationsType = response.data.data;
                    }
                })
        },
        loadMarketingSources() {
            get('/marketing-sources')
                .then((response) => {
                    if (response.data.success) {
                        this.marketing_sources = response.data.data;
                    }
                });
        },
        loadApplicationStatuses() {
            get('/applications/statuses/')
                .then((response) => {
                    if (response.data.success) {
                        this.application_status = response.data.data;
                    }
                });
        },
        getApplication(id) {
            return get(`/applications/${id}`).then((res) => {
                if (res.data.success) {
                    this.clearApplicationForm();
                    this.form.type_id = res.data.data.type_id;
                    this.form.comment = res.data.data.description;
                    this.form.source = res.data.data.marketing_id ? res.data.data.marketing_id : 0;
                    this.form.status = res.data.data.status;
                    this.form.application_id = res.data.data.id;
                    this.form_application = true;
                    this.show_application_list = false;
                    this.is_new_application_form = false;
                    this.product_ids = res.data.data.product_ids
                    return res;
                }
            });
        },
        clearApplicationForm(id = 0) {
            const defaults = {
                type_id: 0,
                theme_ids: [],
                comment: '',
                source: 0,
                status: 0,
                application_comment: ''
            };
            for (let key in this.form) {
                this.form[key] = defaults[key];
            }
            if (this.errors !== undefined) {
                for (let key in this.errors.application) {
                    this.errors.application[key] = false;
                }
            }

            this.is_application_loading = false;
            this.edited_application_id = 0;
            this.form.application_id = id;
            this.application_comments = [];
        },
        closeApplicationForm() {
            this.show_application_list = true;
            this.form_application = false;
        },
        getIsHiddenStatus(iTypeId) {
            if (iTypeId !== this.form.type_id) {
                return 'd-none';
            }
            return '';
        },
    }
}
